export default {
  // Endpoints
  fetchDataInstansi: '/api/v1/customers',
  createInstansi: '/api/v1/customer',
  createAccount: '/api/v1/user',
  createAccountwarehouse: '/api/v1/user/warehouse',
  fetchDataAccounts: '/api/v1/accounts',
  createWarehouse: '/api/v1/warehouse',
  fetchDataWarehouses: '/api/v1/warehouses',
  createProduct: '/api/v1/product',
  createProductArray: '/api/v1/product/batch',
  fetchDataProduct: '/api/v1/products',
  createProductLog: '/api/v1/product/log',
  createCategories: '/api/v1/categories',
  settleProduct: '/api/v1/product/settle',
  receiptProductArray: '/api/v1/product/receipt'
}